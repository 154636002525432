import React from "react";

import Header from "../components/common/header";
import Footer from "../components/common/footer";

import { Container, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            status: null,
            showAlert: false
        }
    }

    handleServerResponse(ok, message, form) {
        this.setState({
            submitting: false,
            status: { ok, message }
        });

        if (ok) {
            form.reset();
        }

        this.setShowAlert(true);
    }

    handleOnSubmit(e) {
        e.preventDefault();
        const form = e.target;


        this.setState({
            submitting: true
        });

        var requestOptions = {
            method: 'POST',
            body: new FormData(form)
        }

        var URL = "https://getform.io/f/16bdbab7-d974-4b28-8249-ff6f606c209b";

        fetch(URL, requestOptions)
            .then(
                r => {
                    this.handleServerResponse(true, "Thank you for contacting me! I will get back to you soon.", form);
                }
            )
            .catch(
                r => {
                    this.handleServerResponse(false, r.response.data.error, form);
                }
            );
    }

    setShowAlert(bool) {
        this.setState({
            showAlert: bool,
        });
    }

    render() {
        return (
            <div>
                <Header pageName={"Contact"}/>
                <Container>
                    {this.state.status && this.state.showAlert && (
                        <Alert variant={this.state.status.ok ? "success" : "danger"} onClose={() => this.setShowAlert(false)} dismissible>
                            {this.state.status.message}
                        </Alert>
                    )}
                    <Form className={"contact-form"} onSubmit={this.handleOnSubmit.bind(this)}>
                        <div className={"g-recaptcha"} data-sitekey={"6LdUr7UZAAAAAIxQ9nid4Dbq4Otcc9Tv2LkptTQs"}></div>

                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control placeholder="Type your name" className={"contact-name"} name="Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Type your email address" className={"contact-name"} name="Email" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>How may I help you?</Form.Label>
                                    <Form.Control as="textarea" rows="5" placeholder="Type message" id="form-message" className={"contact-message"} name="Message" />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                        
                        <div className={"submissionButton"}>
                            <Button variant="primary" type="submit">Send message</Button>
                        </div>
                    </Form>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Contact;
